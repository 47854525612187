<template>
  <div v-if="!loading">
    <div class="d-flex justify-content-end">
      <feather-icon
        v-if="this.isUpdate && organizationRequiresEditConfirmation"
        v-b-tooltip.noninteractive.hover.bottom
        :title="$t('Edit')"
        class="cursor-pointer ml-1"
        :icon="isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
        size="36"
        @click="isEditAble"
      />
    </div>
    <p class="text-center font-weight-bolder font-large-1">
      {{ isUpdate ? $t('Update Pricing Rule') : $t('Add a Pricing Rule') }}
    </p>
    <error-alert
      :fields="pricingFields"
      :error="error"
    />
    <b-form>
      <validation-observer
        ref="pricingRulesCreateVal"
      >
        <div class="mb-2">
          <b-row>
            <b-col
              md="6"
            >
              <div>
                <component
                  :is="pricingFields[field].type"
                  v-for="field in ['pricing_rule_name', 'currency_id', 'pricing_by_month_id']"
                  :key="field"
                  v-model="pricingRules[field]"
                  v-bind="getProps(field)"
                  :is-editable="isEdit"
                />
              </div>
              <div>
                <h1 class="text-left font-medium-5 font-weight-bolder">
                  Grid
                </h1>
                <component
                  :is="pricingFields[field].type"
                  v-for="field in ['base_price_selection_id']"
                  :key="field"
                  v-model="pricingRules[field]"
                  v-bind="getProps(field)"
                  :is-editable="isEdit"
                />
              </div>
              <div>
                <b-row class="d-flex align-items-start ml-md-1 mt-1">
                  <b-col
                    md="2"
                    class="flex align-self-center"
                  >
                    <span>Prorate</span>
                  </b-col>
                  <b-col md="2">
                    <component
                      :is="pricingFields[field].type"
                      v-for="field in ['prorate_week']"
                      :key="field"
                      v-model="pricingRules[field]"
                      v-bind="getProps(field)"
                      :is-editable="isEdit"
                    />
                  </b-col>
                  <b-col md="4">
                    <component
                      :is="pricingFields[field].type"
                      v-for="field in ['prorate_month_id']"
                      :key="field"
                      v-model="pricingRules[field]"
                      v-bind="getProps(field)"
                      :is-editable="isEdit"
                    />
                  </b-col>
                  <b-col
                    md="2"
                    class="mt-sm-auto"
                  >
                    <component
                      :is="pricingFields[field].type"
                      v-for="field in ['prorate_days']"
                      :key="field"
                      v-model="pricingRules[field]"
                      v-bind="getProps(field)"
                      :is-editable="isEdit"
                    />
                  </b-col>
                  <b-col
                    md="2"
                    class="flex align-self-center"
                  >
                    <span>Days</span>
                  </b-col>
                </b-row>
              </div>
              <div>
                <component
                  :is="pricingFields[field].type"
                  v-for="field in ['change_charge_end_time_return_id', 'change_charge_start_time_ship_id', 'days_in_week', 'days_in_month', 'grace_period', 'default_unit_id', 'allowable_days', 'compute_total_cost_for_id', 'labor_cost_based_on_id', 'equip_cost_based_on_id', 'misc_cost_based_on_id']"
                  :key="field"
                  v-model="pricingRules[field]"
                  v-bind="getProps(field)"
                  :is-editable="isEdit"
                />
              </div>
            </b-col>
            <b-col
              md="6"
            >
              <div>
                <component
                  :is="pricingFields[field].type"
                  v-for="field in ['is_active', 'default_item_price_fill_order', 'service_charge_first_invoice', 'show_cost_deleted_lines', 'auto_extend_charge_duration', 'check_credit_limit_hold_orders']"
                  :key="field"
                  v-model="pricingRules[field]"
                  v-bind="getProps(field)"
                  :is-editable="isEdit"
                />
              </div>
              <div>
                <h1 class="text-left font-medium-5 font-weight-bolder">
                  Lost and Repair Invoice
                </h1>
                <component
                  :is="pricingFields[field].type"
                  v-for="field in ['reparation_cost']"
                  :key="field"
                  v-model="pricingRules[field]"
                  v-bind="getProps(field)"
                  :is-editable="isEdit"
                />
              </div>
              <div>
                <h1 class="text-left font-medium-5 font-weight-bolder">
                  Consumable
                </h1>
                <component
                  :is="pricingFields[field].type"
                  v-for="field in ['consumable_discounted_amount', 'consumable_first_invoice']"
                  :key="field"
                  v-model="pricingRules[field]"
                  v-bind="getProps(field)"
                  :is-editable="isEdit"
                />
              </div>
              <div>
                <h1 class="text-left font-medium-5 font-weight-bolder">
                  Non Chargeable Days
                </h1>
                <b-row>
                  <b-col md="6">
                    <component
                      :is="pricingFields[field].type"
                      v-for="field in ['non_chargeable_sunday', 'non_chargeable_monday', 'non_chargeable_tuesday', 'non_chargeable_wednesday']"
                      :key="field"
                      v-model="pricingRules[field]"
                      v-bind="getProps(field)"
                      :is-editable="isEdit"
                    />
                  </b-col>
                  <b-col md="6">
                    <component
                      :is="pricingFields[field].type"
                      v-for="field in ['non_chargeable_thursday', 'non_chargeable_friday', 'non_chargeable_saturday']"
                      :key="field"
                      v-model="pricingRules[field]"
                      v-bind="getProps(field)"
                      :is-editable="isEdit"
                    />
                  </b-col>
                </b-row>
              </div>
              <div>
                <h1 class="text-left font-medium-5 font-weight-bolder">
                  PO Price
                </h1>
                <component
                  :is="pricingFields[field].type"
                  v-for="field in ['po_price_items_parts_id']"
                  :key="field"
                  v-model="pricingRules[field]"
                  v-bind="getProps(field)"
                  :is-editable="isEdit"
                />
              </div>
              <div>
                <h1 class="text-left font-small-4 font-weight-bolder">
                  When above price not defined use:
                </h1>
                <component
                  :is="pricingFields[field].type"
                  v-for="field in ['po_price_misc_id', 'po_price_subrent_id', 'po_price_inventory_id']"
                  :key="field"
                  v-model="pricingRules[field]"
                  v-bind="getProps(field)"
                  :is-editable="isEdit"
                />
              </div>
            </b-col>
          </b-row>
        </div>
        <portal to="body-footer">
          <div class="d-flex mt-2 pb-1 justify-content-between">
            <div>
              <b-button
                v-if="isFormChanged"
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="loader"
              >
                <feather-icon
                  icon="LCancelIcon"
                  size="16"
                />
                {{ $t('Cancel') }}
              </b-button>
              <b-button
                v-else
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="cancel"
              >
                {{ $t('Back to List') }}
              </b-button>
            </div>
            <div>
              <b-button
                variant="success"
                class="saveBtn font-medium-1 font-weight-bolder"
                :disabled="isCreateForm ? false : !isFormChanged"
                @click="submit"
              >
                {{ $t('SAVE') }}
              </b-button>
            </div>
          </div>
        </portal>
      </validation-observer>
    </b-form>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
  <div v-else>
    <skeleton />
  </div>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import { required } from '@/libs/validations/validations'
import { scrollToError } from '@core/utils/utils'
import { ValidationObserver } from 'vee-validate'
import {
  BButton, BRow, BCol, BForm, VBToggle, VBTooltip,
} from 'bootstrap-vue'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import config from '../pricingConfig'
import Skeleton from './Skeleton.vue'

export default {
  name: 'PricingForm',
  components: {
    BButton,
    BRow,
    BCol,
    BForm,
    ErrorAlert,
    ValidationObserver,
    Skeleton,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  inject: ['pricingRulesModuleName'],
  props: {
    isCreateForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      error: {},
      loading: false,
      onSubmit: false,
      pricingRule: null,
      isEdit: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  computed: {
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    pricingRules() {
      return this.$store.state[this.pricingRulesModuleName].pricingRulesForm
    },
    pricingRulesClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].settingCatalogPricingRulesForm
    },
    isFormChanged() {
      return JSON.stringify(this.pricingRules) !== this.pricingRulesClone
    },
    isUpdate() {
      return this.pricingRules.id
    },
    isFormTriggered() {
      return this.pricingRules.base_price_selection_id?.id !== 6
    },
  },
  created() {
    this.loading = true
    this.$store.dispatch('listModule/pricingRulesEnumList').then(() => {
      this.loading = false
    }).catch(err => {
      this.error = err.response?.data
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: '',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          text: 'This item is already added above !',
        },
      })
    })
  },
  mounted() {
    if (this.isCreateForm) this.isEdit = false
  },
  methods: {
    isEditAble() {
      this.isEdit = !this.isEdit
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    },
    submit() {
      this.error = {}
      this.onSubmit = true

      this.$refs.pricingRulesCreateVal.validate()
        .then(success => {
          if (success) {
            const data = this.pricingRules
            const pricingRulesId = this.pricingRules.id
            const defaultPricingRules = this.mappingFields(Object.keys(this.pricingFields), data, {
              id: pricingRulesId,
              currency_id: data.currency_id?.id ?? null,
              pricing_by_month_id: data.pricing_by_month_id?.id ?? null,
              base_price_selection_id: data.base_price_selection_id?.id ?? null,
              prorate_month_id: data.prorate_month_id?.id,
              prorate_week: data.prorate_week ?? null,
              prorate_days: data.prorate_days,
              change_charge_end_time_return_id: data.change_charge_end_time_return_id?.id ?? null,
              change_charge_start_time_ship_id: data.change_charge_start_time_ship_id?.id ?? null,
              default_unit_id: data.default_unit_id?.id ?? null,
              allowable_days: data.allowable_days,
              compute_total_cost_for_id: data.compute_total_cost_for_id?.id ?? null,
              labor_cost_based_on_id: data.labor_cost_based_on_id?.id ?? null,
              equip_cost_based_on_id: data.equip_cost_based_on_id?.id ?? null,
              misc_cost_based_on_id: data.misc_cost_based_on_id?.id ?? null,
              po_price_items_parts_id: data.po_price_items_parts_id?.id ?? null,
              po_price_misc_id: data.po_price_misc_id?.id ?? null,
              po_price_subrent_id: data.po_price_subrent_id?.id ?? null,
              po_price_inventory_id: data.po_price_inventory_id?.id ?? null,
              is_active: String(data.is_active),
            })
            this.sendNotification(this, { ...defaultPricingRules, pricingRulesId }, `${this.MODULE_NAME}/${this.pricingRules.id ? 'update' : 'create'}`)
              .then(res => {
                this.clear()
                // this.$store.dispatch(`${this.MODULE_NAME}/get`, this.$route.params.id)
                this.$router.replace({
                  name: 'settings-catalog-price-list',
                  params: { id: res.data.data?.id },
                })
              })
              .catch(err => {
                this.error = err.response?.data
              }).finally(() => {
                this.onSubmit = false
              })
          } else {
            scrollToError(this, this.$refs.pricingRulesCreateVal)
          }
        })
    },
    clear() {
      this.$refs.pricingRulesCreateVal.reset()
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, {})
    },
    cancel() {
      this.$router.push({ name: 'settings-catalog-price-list' })
    },
    loader() {
      this.$refs.pricingRulesCreateVal.reset()
      const smth = JSON.parse(this.pricingRulesClone)
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, smth)
      // pricingRulesClone
    },
    getProps(fieldName) {
      const dataOF = {
        field: {
          ...this.pricingFields[fieldName],
          ...this.pricingFields.prorate_week.rules = `${this.isFormTriggered ? 'required' : ''}`,
          ...this.pricingFields.prorate_month_id.rules = `${this.isFormTriggered ? 'required' : ''}`,
        },
        name: fieldName,
      }
      // if (fieldName === 'state_id') {
      //   data.parentValue = this.brand.country_id?.id
      // }
      return dataOF
    },
  },
  setup() {
    const MODULE_NAME = 'pricing-rules'
    const MODULE_NAME_CLONE = 'cloneData'
    const { pricingFields } = config()
    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      pricingFields,
    }
  },
}
</script>
