<template>
  <div>
    <pricing-rules-form
      v-if="pricingRules.id"
      ref="pricing-rules-form"
    />
    <div v-else>
      <div v-if="!error">
        <b-row>
          <b-col md="6">
            <b-skeleton type="input" />
          </b-col>
          <b-col md="6">
            <b-skeleton type="input" />
          </b-col>
        </b-row>
        <b-row class="pt-2">
          <b-col md="6">
            <b-skeleton type="input" />
          </b-col>
          <b-col md="6">
            <b-skeleton type="input" />
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <component-not-found
          title="Pricing List"
          :button-link="{ name: 'settings-catalog-price-list'}"
        />
      </div>
    </div>
  </div>

</template>
<script>
import {
  BRow, BCol, BSkeleton,
} from 'bootstrap-vue'
import config from '../pricingConfig'
import PricingRulesForm from '../components/PricingRulesForm.vue'

export default {
  name: 'PricingRulesUpdate',
  components: {
    PricingRulesForm,
    BRow,
    BCol,
    BSkeleton,
  },
  inject: ['pricingTiersModuleName', 'pricingRulesModuleName'],
  data() {
    return {
      error: false,
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['pricing-rules-form']) this.$refs['pricing-rules-form'].showConfirmationModal(next)
  },
  computed: {
    pricingRules() {
      return this.$store.state[this.pricingRulesModuleName].pricingRulesForm
    },
  },
  mounted() {
    this.$store.dispatch(`${this.pricingRulesModuleName}/get`, this.$route.params.id).then(res => {
      const { data } = res.data
      const pricingRules = this.mappingFields(Object.keys(this.pricingFields), data, {
        id: data.id,
        pricing_by_month_id: data.pricing_by_month,
        prorate_month_id: data.prorate_month,
        base_price_selection_id: data.base_price_selection,
        change_charge_end_time_return_id: data.change_charge_end_time_return,
        change_charge_start_time_ship_id: data.change_charge_start_time_ship,
        default_unit_id: data.default_unit,
        compute_total_cost_for_id: data.compute_total_cost_for,
        labor_cost_based_on_id: data.labor_cost_based_on,
        equip_cost_based_on_id: data.equip_cost_based_on,
        misc_cost_based_on_id: data.misc_cost_based_on,
        po_price_items_parts_id: data.po_price_items_parts,
        po_price_misc_id: data.po_price_misc,
        po_price_subrent_id: data.po_price_subrent,
        po_price_inventory_id: data.po_price_inventory,
        currency_id: data.currency,
      })
      this.$store.commit(`${this.pricingRulesModuleName}/SET_FORM`, pricingRules)
      this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_PRICING_RULES_FORM_CLONE`, pricingRules)
    }).catch(err => {
      this.error = true
      this.errorNotification(this, err)
    })
  },
  created() {
    this.$store.commit(`${this.pricingRulesModuleName}/SET_FORM`, {})
  },
  setup() {
    const MODULE_NAME_CLONE = 'cloneData'
    const { pricingFields } = config()
    return {
      MODULE_NAME_CLONE,
      pricingFields,
    }
  },
}
</script>
